import React from "react"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import { MenuItem } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import SendIcon from "@material-ui/icons/Send"

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "calc(100% - 1rem)",
    },
  },
}))

const services = [
  {
    value: "Wedding",
  },
  {
    value: "Travel",
  },
  {
    value: "Tourism",
  },
  {
    value: "Media",
  },
]
export default function FormPropsTextFields() {
  const classes = useStyles()

  const [firstname, setFirstname] = React.useState("")
  const [lastname, setLastname] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [service, setService] = React.useState("")
  const [message, setMessage] = React.useState("")

  const handleChange = (event, setter) => {
    setter(event.target.value)
  }

  const handleSubmit = async () => {
    const url = "https://us-central1-flyingflo-6a558.cloudfunctions.net/contact"
    const data = {
      firstname: firstname || "",
      lastname: lastname || "",
      email: email || "",
      service: service || "",
      message: message || "",
    }

    let payload = JSON.stringify(data, undefined, "\t")
    // Solution to handle the accents corrupting the payload into invalid JSON
    payload = payload.replace(/[\u007F-\uFFFF]/g, chr => {
      return "\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4)
    })

    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: payload, // body data type must match "Content-Type" header
    })

    setFirstname("")
    setLastname("")
    setEmail("")
    setService("")
    setMessage("")
  }

  return (
    <form className={classes.root}>
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} sm={6}>
          <TextField
            required
            value={firstname}
            onChange={event => handleChange(event, setFirstname)}
            id="firstName"
            label="First Name"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={lastname}
            onChange={event => handleChange(event, setLastname)}
            id="lastName"
            label="Last Name"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            value={email}
            onChange={event => handleChange(event, setEmail)}
            id="email"
            label="Email"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="type"
            select
            label="Type of service"
            value={service}
            onChange={event => handleChange(event, setService)}
            variant="outlined"
            SelectProps={{
              MenuProps: {
                disableScrollLock: true,
              },
            }}
          >
            {services.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={message}
            onChange={event => handleChange(event, setMessage)}
            id="message"
            label="Message"
            multiline
            rows={6}
            variant="outlined"
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={<SendIcon />}
            onClick={handleSubmit}
          >
            Send
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
