import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"

const ContactPage = () => (
  <Layout contentOverImage>
    <SEO title="Contact" />
    <div
      style={{
        margin: "2rem auto 2rem",
        maxWidth: "1000px",
        padding: "2rem 1rem",
        backgroundColor: "rgba(255,255,255,.9)",
        borderRadius: "5px",
        color: "white",
      }}
    >
      <h1 style={{ textAlign: "center", color: "black" }}>Contact Us</h1>
      <ContactForm />
    </div>
  </Layout>
)

export default ContactPage
